import React from 'react'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import PropTypes from 'prop-types'
import useLineAnimation from '../../../common/hooks/useLineAnimation'

const WhatWeDid = ({ work }) => {
  const lineRef = useLineAnimation()

  return (
    <section
      className='overflow-hidden'
      ref={lineRef}
    >
      <div className='line h-[1px] w-full bg-line-grey' />
      <FadeInUpAnimation className='grid md:grid-cols-3 md:my-10 my-5 '>
        <div className='lg:text-5xl text-4xl mb-10'>What we did</div>
        <p className='col-span-2'>{work.whatWeDidDesc}</p>
      </FadeInUpAnimation>
    </section>
  )
}

export default WhatWeDid

WhatWeDid.propTypes = {
  work: PropTypes.object.isRequired
}
