import React, { forwardRef } from 'react'
import TgcLink from '../../../common/TgcLink/TgcLink'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import Projects from '../../../common/Projects/Projects'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation.jsx'
import { graphql, useStaticQuery } from 'gatsby'
import { map, sortBy } from 'lodash'
import PropTypes from 'prop-types'

const query = graphql`
  query getRelatedWorks {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "works" }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              id
              url
              title
              subTitle
              imgs {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

const MoreWork = forwardRef((props, triggerRef) => {
  const {
    allFile: { edges }
  } = useStaticQuery(query)

  const projects = map(edges, 'node.childMarkdownRemark.frontmatter')
  const relatedProjects = projects.filter((project) =>
    props.relatedWorkIds.includes(project.id)
  )
  const sortedProjects = sortBy(relatedProjects, (project) => project.id)

  return (
    <article
      className='mt-8 mb-24 bg-white'
      ref={triggerRef}
    >
      <FadeInUpAnimation>
        <TextWithDots className='lg:my-24'>more work</TextWithDots>
        <Projects sortedProjects={sortedProjects} />
        <TgcLink
          className='inline-block lg:absolute lg:top-0 lg:right-0 lg:mt-3 openBtn'
          href='/work/'
        >
          View all projects
        </TgcLink>
      </FadeInUpAnimation>
    </article>
  )
})

MoreWork.displayName = 'MoreWork'

export default MoreWork

MoreWork.propTypes = {
  relatedWorkIds: PropTypes.arrayOf(PropTypes.number)
}
